<template>
  <v-form
    ref="labResultForm"
    v-model="valid"
    @submit.prevent="saveLabResult"
  >
    <v-card>
      <v-card-title>
        <v-icon class="mr-3 mt-n1">
          fal fa-file-medical
        </v-icon>
        {{ $t('Add') }} {{ $tc('Lab Result') }}
        <v-spacer />
        <v-btn
          icon
          tile
          color="grey"
          @click.stop="$emit('cancel')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text xstyle="height: 300px;">
        <v-row>
          <v-col
            v-show="pdf"
            cols="6"
          >
            <object
              :data="pdf"
              type="application/pdf"
              width="100%"
              height="600px"
              style="border: 1px solid"
            />
          </v-col>
          <v-col>
            <v-row>
              <v-col
                cols="1"
                style="max-width:200px"
              >
                <v-select
                  v-model="lab_result.method"
                  :rules="rules.required"
                  :items="[
                    { value: 'pcr', text: 'PCR', disabled: $auth.user().client && !$auth.user().client.services.map(x => x.name).includes('OneChoice Reports - Molecular') },
                    { value: 'ngs', text: 'NGS', disabled: $auth.user().client && !$auth.user().client.services.map(x => x.name).includes('OneChoice Reports - Molecular') },
                    { value: 'culture', text: $options.filters.capitalize($tc('culture')), disabled: $auth.user().client && !$auth.user().client.services.map(x => x.name).includes('OneChoice Reports - Culture') },
                    { value: 'fusion', text: 'Fusion', disabled: $auth.user().client && !$auth.user().client.services.map(x => x.name).includes('OneChoice Reports - Fusion') },
                  ]"
                  :label="$t('method') | capitalize"
                  class="no-border"
                  dense
                >
                  <template #selection="{ item }">
                    <v-chip
                      outlined
                      small
                      label
                      class="px-2 mx-0 text-uppercase"
                    >
                      {{ item.text }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="auto"
                xcols="6"
                xmd="2"
              >
                <v-text-field
                  v-model="lab_result.identifier"
                  :label="$t('accession_report') | capitalize"
                  :rules="rules.required"
                  class="required"
                  dense
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-combobox
                  v-model.trim="lab_result.sample_type"
                  :label="$t('Specimen Source')"
                  :rules="[rules.required, rules.source].flat()"
                  class="required"
                  :loading="loading.sources"
                  :items="sources.map(x => x.name)"
                  :return-object="false"
                  cache-items
                  :search-input.sync="search.sources"
                  :hint="$t('hints.sample_type')"
                  dense
                />
              </v-col>
              <v-col
                xcols="4"
                xmd="1"
                class="required"
              >
                <date-filter
                  :value.sync="lab_result.created"
                  :label="$t('dates.report')"
                  :rules="rules.required"
                  :icon="false"
                  :range="false"
                  :clearable="false"
                  use-timezone
                  dense
                />
              </v-col>
              <v-col
                xcols="4"
                xmd="2"
              >
                <date-filter
                  :value.sync="lab_result.draw"
                  :label="$t('dates.draw')"
                  :icon="false"
                  :range="false"
                  :clearable="false"
                  :hint="$t('hints.draw_date')"
                  use-timezone
                  dense
                />
              </v-col>
              <v-col
                xcols="4"
                xmd="2"
              >
                <date-filter
                  :value.sync="lab_result.received"
                  :label="$t('dates.received')"
                  :icon="false"
                  :range="false"
                  :clearable="false"
                  use-timezone
                  dense
                />
              </v-col>
            </v-row>
            <v-divider class="mt-2 mb-n1" />
            <p class="text-overline my-1">
              <v-icon
                class="mt-n1"
                small
              >
                fal fa-hospital-alt fa-fw
              </v-icon>
              {{ $tc('facility', 1) }}
            </p>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-combobox
                  v-model.trim="lab_result.facility.name"
                  :search-input.sync="search.facilities"
                  no-data-text="Enter a facility name"
                  :items="facilities"
                  item-text="name"
                  :label="$t('name') | capitalize"
                  clearable
                  dense
                  @change="x => typeof x === 'string' ? lab_result.facility.name = x : lab_result.facility = x"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model.trim="lab_result.facility.address"
                  :label="$t('address') | capitalize"
                  dense
                />
              </v-col>
              <v-col
                cols="3"
                md="1"
              >
                <v-text-field
                  v-model.trim="lab_result.facility.zip"
                  :label="$t('postcode')"
                  dense
                  @keyup="verifyZip(lab_result.facility.zip, 'facility')"
                />
              </v-col>
              <v-col
                cols="6"
                md="2"
              >
                <v-text-field
                  v-model.trim="lab_result.facility.city"
                  :label="$t('city') | capitalize"
                  dense
                />
              </v-col>
              <v-col
                cols="3"
                md="1"
              >
                <location-filter
                  :value.sync="lab_result.facility.state"
                  :label="$t('state') | capitalize"
                  :icon="false"
                  :multiple="false"
                  :small-chips="false"
                  :clearable="false"
                  dense
                />
              </v-col>
            </v-row>
            <v-divider class="mt-2 mb-n1" />
            <p class="text-overline my-1">
              <v-icon
                class="mt-n1"
                small
              >
                fal fa-user-md fa-fw
              </v-icon>
              {{ $tc('provider', 1) }}
            </p>
            <v-row>
              <v-col
                cols="6"
                md="2"
              >
                <v-text-field
                  v-model.number="lab_result.provider.npi"
                  :rules="[validateNpi]"
                  label="NPI"
                  :class="{'required': lab_result.provider && lab_result.provider.last_name}"
                  dense
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-text-field
                  v-model.trim="lab_result.provider.first_name"
                  :loading="loading.providers"
                  :label="$t('first name') | capitalize"
                  dense
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-text-field
                  v-model.trim="lab_result.provider.last_name"
                  :loading="loading.providers"
                  :label="$t('last name') | capitalize"
                  :rules="lab_result.provider && lab_result.provider.npi ? rules.required : []"
                  :class="{'required': lab_result.provider && lab_result.provider.npi}"
                  dense
                />
              </v-col>
              <v-col
                cols="6"
                md="2"
              >
                <v-text-field
                  v-model.trim="lab_result.provider.phone"
                  :loading="loading.providers"
                  :label="$t('phone') | capitalize"
                  dense
                />
              </v-col>
              <v-col
                cols="6"
                md="2"
              >
                <v-text-field
                  v-model.trim="lab_result.provider.fax"
                  :loading="loading.providers"
                  :label="$t('fax') | capitalize"
                  dense
                />
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-n1" />
            <p class="text-overline my-1">
              <v-icon
                class="mt-n1"
                small
              >
                fal fa-bed fa-fw
              </v-icon>
              {{ $tc('patient', 1) }}
            </p>
            <v-row>
              <v-col
                cols="6"
                md="2"
              >
                <v-text-field
                  v-model="lab_result.patient.id"
                  :rules="rules.required"
                  class="required"
                  :label="$t('Patient ID')"
                  dense
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-text-field
                  v-model="lab_result.patient.first_name"
                  :rules="rules.required"
                  class="required"
                  :label="$t('first name') | capitalize"
                  dense
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-text-field
                  v-model="lab_result.patient.last_name"
                  :rules="rules.required"
                  class="required"
                  :label="$t('last name') | capitalize"
                  dense
                />
              </v-col>
              <v-col
                cols="3"
                md="2"
                class="required"
              >
                <date-filter
                  :value.sync="lab_result.patient.dob"
                  :rules="rules.required"
                  :label="$t('DOB')"
                  :icon="false"
                  :range="false"
                  :clearable="false"
                  dense
                />
              </v-col>
              <v-col md="2">
                <gender-filter
                  :value.sync="lab_result.patient.gender"
                  :rules="rules.required"
                  class="required"
                  :label="$tc('gender', 1) | capitalize"
                  dense
                />
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-n1" />
            <p class="text-overline my-1">
              <v-icon
                class="mt-n1"
                small
              >
                fal fa-file-medical fa-fw
              </v-icon>
              {{ $t('Clinical Data') }}
            </p>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-combobox
                  v-model="lab_result.allergies"
                  :loading="loading.allergies"
                  :label="$t('allergies') | capitalize"
                  :hint="$t('hints.allergies')"
                  :delimiters="[',']"
                  :items="allergens.map(x => x.name)"
                  cache-items
                  :search-input.sync="search.allergens"
                  deletable-chips
                  hide-selected
                  small-chips
                  multiple
                  dense
                />
              </v-col>
              <v-col>
                <v-combobox
                  v-model="lab_result.diagnosis"
                  :loading="loading.diagnoses"
                  :label="$t('Diagnosis Codes')"
                  :hint="$t('hints.diagnoses')"
                  :delimiters="[',']"
                  :items="diagnoses.map(x => x.icd_10_code)"
                  :search-input.sync="search.diagnoses"
                  cache-items
                  deletable-chips
                  hide-selected
                  small-chips
                  multiple
                  dense
                />
              </v-col>
              <v-col
                v-if="lab_result.patient.gender == 'female'"
                cols="2"
              >
                <v-checkbox
                  v-model="lab_result.pregnant"
                  :label="$t('pregnant') | capitalize"
                  class="mt-n4"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                xcols="12"
                xmd="9"
              >
                <v-combobox
                  v-model="lab_result.organisms"
                  :hint="(lab_result.method == 'culture' || lab_result.method == 'fusion') && lab_result.organisms.length > 0 ? $t('hints.sensitivities') : !lab_result.organisms.length ? $t('form.leave_blank', { item: $tc('organism', 1) }) : ''"
                  :items="filteredOrganisms"
                  no-filter
                  :loading="loading.organisms"
                  persistent-hint
                  :search-input.sync="search.organisms"
                  deletable-chips
                  dense
                  hide-selected
                  item-text="name"
                  item-value="name"
                  :label="$t('Detected Organisms')"
                  multiple
                  small-chips
                  counter
                  @change="val => lab_result.organisms = val.map(x => typeof x === 'object' ? x : ({
                    name: x, detected: true, sensitivities: []
                  }) )"
                >
                  <template
                    #counter="{ props }"
                  >
                    <div class="v-counter">
                      <template v-if="!props.value">
                        {{ $t('no x detected', { item: $tc('organism', 1) }) }}
                      </template>
                      <template v-else>
                        {{ props.value }} {{ $tc('organism', props.value) }} {{ $t('detected') }}
                      </template>
                    </div>
                  </template>
                  <template
                    v-if="lab_result.method == 'culture' || lab_result.method == 'fusion'"
                    #selection="{ item }"
                  >
                    <organism-chip
                      :organism.sync="item"
                      @remove="lab_result.organisms = lab_result.organisms.filter(x => x != item)"
                      @update="x => item = x"
                    />
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row
              v-if="lab_result.method != 'culture'"
              justify="space-between"
            >
              <v-col
                cols="12"
                md="2"
              >
                <v-select
                  v-model="resistance_tested"
                  :rules="rules.required"
                  :label="$tc('resistance', 1) | capitalize"
                  class="required"
                  :items="[
                    { value: true, text: $t('Tested') },
                    { value: false, text: $t('Not Tested') },
                  ]"
                  dense
                  @change="updateResistance"
                />
              </v-col>
              <v-col>
                <v-combobox
                  v-model="lab_result.resistance"
                  :disabled="!resistance_tested"
                  :loading="loading.genes"
                  :label="$t('Detected Resistance Genes')"
                  :hint="resistance_tested && lab_result.resistance.length > 0 ? $t('hints.resistance') : $t('form.leave_blank', { item: $tc('resistance', 1) })"
                  :persistent-hint="resistance_tested"
                  :search-input.sync="search.genes"
                  :items="genes.filter(x => x.gene != null).map(x => x.gene)"
                  item-text="gene"
                  cache-items
                  deletable-chips
                  hide-selected
                  small-chips
                  multiple
                  dense
                  :counter="resistance_tested"
                  @change="val => lab_result.resistance = val.map(x => typeof x === 'object' ? x : ({
                    gene: x, detected: true
                  }) )"
                >
                  <template
                    #counter="{ props }"
                  >
                    <div class="v-counter">
                      <template v-if="!props.value">
                        {{ $t('no x detected', { item: $tc('resistance', 1) }) }}
                      </template>
                      <template v-else>
                        {{ props.value }} {{ $t('detected') }}
                      </template>
                    </div>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-container
          class="fill-height"
        >
          <v-row
            justify="space-between"
            no-gutters
          >
            <v-col
              cols="12"
              md="3"
              class="py-0 mr-auto"
            >
              <v-file-input
                v-model="lab_result.pdf"
                accept="application/pdf"
                prepend-icon="fal fa-file-pdf"
                :placeholder="$t('upload', { file:'PDF' })"
                dense
                outlined
                hide-details
                @change="uploadPDF()"
              />
            </v-col>
            <v-col
              :class="{ 'col-auto': !$vuetify.breakpoint.mobile }"
            >
              <v-btn
                text
                :class="$vuetify.breakpoint.mobile ? 'mb-1' : 'mr-1'"
                :block="$vuetify.breakpoint.mobile"
                @click="$emit('cancel')"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                :class="$vuetify.breakpoint.mobile ? 'mb-1' : 'mr-1'"
                color="primary lighten-2"
                :block="$vuetify.breakpoint.mobile"
                @click="saveLabResult(false)"
              >
                {{ $t('Save') }}{{ $t('ampersand') }}{{ $t('Add More') }}
              </v-btn>
              <v-btn
                color="primary"
                :block="$vuetify.breakpoint.mobile"
                @click="saveLabResult(true)"
              >
                {{ $t('Save') }}{{ $t('ampersand') }}{{ $t('Close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
      <v-fade-transition>
        <v-overlay :value="loading.pdf || loading.button">
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
      </v-fade-transition>
    </v-card>
  </v-form>
</template>
<script>
export default {
  components: {
    DateFilter: () => import('@/components/tables/filters/DateFilter.vue'),
    OrganismChip: () => import('@/components/organism/OrganismChip.vue'),
    LocationFilter: () => import('@/components/tables/filters/LocationFilter.vue'),
    GenderFilter: () => import('@/components/tables/filters/GenderFilter.vue'),
  },
  data () {
    return {
      valid: false,
      open: false,
      providers: [],
      patients: [],
      organisms: [],
      facilities: [],
      genes: [],
      allergens: [],
      diagnoses: [],
      resistance_tested: null,
      sources: [],
      lab_result: {
        method: 'pcr',
        provider: {},
        facility: {},
        patient: {},
        organisms: [],
      },
      loading: {
        button: false,
        organisms: false,
        allergens: false,
        diagnoses: false,
        providers: false,
        sources: false,
        genes: false,
        pdf: false,
      },
      search: {
        organisms: null,
        allergens: null,
        diagnoses: null,
        facilities: null,
        sources: null,
        genes: null,
      },
      pdf: null,
      rules: {
        required: [
          v => v != null || this.$t('required'),
        ],
        source: [
          v => !/^\d+$/.test(v) || 'Not a valid source',
          v => v && !['swab','tube','test','sample'].includes(v.trim().toLowerCase()) || 'Not a valid source',
          v => v != this.lab_result.facility.name || 'Not a valid source',
          v => (this.$auth.user().client ? v != this.$auth.user().client.name : true) || 'Not a valid source',
        ],
      },
    }
  },
  computed: {
    filteredOrganisms () {
      if (!this.search.organisms) return this.organisms.map(x => x.name)

      var searchTerm = this.search.organisms.toLocaleLowerCase()
      return this.organisms.map(x => x.name)
        .filter(x => x.toLocaleLowerCase().indexOf(searchTerm) > -1)
        .sort((a, b) => a.toLowerCase().indexOf(searchTerm) < b.toLowerCase().indexOf(searchTerm) ? -1 : 1)
    },
  },
  watch: {
    'lab_result.provider.npi' (npi) {
      if (npi.toString().length > 9) {
        this.loading.providers = true
        this.fetchItems('providers', npi, 'npi').then(() => {
          if (this.providers.length == 1) {
            this.$set(this.lab_result, 'provider', this.providers[0])
            delete this.provider.fax
          } else {
            this.verifyNpi(npi)
          }
          this.loading.providers = false
        })
      }
    },
    'lab_result.patient.id' (id) {
      this.fetchItems('patients', id, 'record', { pure: true }).then(() => {
        if (this.patients.length == 1) {
          this.patients[0].id = id
          this.$set(this.lab_result, 'patient', this.patients[0])
        }
      })
    },
    'search.facilities'(name) {
      this.fetchItems('facilities', name)
    },
    'search.sources'(val) {
      this.fetchItems('admin/sources', val)
    },
    'search.organisms'(val) {
      this.fetchItems('admin/organisms?quick=true', val)
    },
    'search.genes'(val) {
      this.fetchItems('admin/resistances/genes', val)
    },
    'search.allergens'(val) {
      this.fetchItems('admin/allergens', val)
    },
    'search.diagnoses'(val) {
      this.fetchItems('admin/diagnoses', val)
    },
  },
  methods: {
    fetchItems (route, search = null, field = null, params = {}) {
      var type = route.includes('/') ? route.split('/').pop().split('?').shift() : route
      this.loading[type] = true
      const promise = this.axios.get(route, {
        params: {
          ...params,
          count: 20,
          [field ? field : 'search']: search,
        },
      })
      return promise.then((response) => {
        this[type] = response.data.data
      }).catch(error => {
        if (error.response?.data?.message) {
          this.$toast.error(error.response.data.message)
        }
      })
      .finally(() => { this.loading[type] = false })
    },
    saveLabResult(close) {
      this.$refs.labResultForm.validate()
      if (this.valid) {
        this.loading.button = true
        const payload = JSON.parse(JSON.stringify(this.lab_result))
        if (payload.organisms.length == 0) {
          payload.negative = true
        }
        if (this.resistance_tested && payload.resistance.length == 0) {
          payload.resistance = [{ gene: 'mecA', detected: false }]
        }
        if (!this.lab_result.provider.npi) {
          delete(payload.provider)
        }
        if (!this.lab_result.facility.name) {
          delete(payload.facility)
        }
        if (!this.lab_result.resistance) {
          delete(payload.resistance)
        }
        this.axios.post('/lab_results', payload)
          .then(() => {
            this.$refs.labResultForm.reset()
            this.$toast.success('Lab Result successfully saved')
            this.$emit('lab-result-added', close)
          })
          .catch(error => {
            if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
          })
          .finally(() => { this.loading.button = false })
      }
    },
    uploadPDF () {
      if (this.lab_result.pdf) {
        this.loading.pdf = true
        const formData = new FormData()
        for (const [key, value] of Object.entries(this.lab_result)) {
          if (typeof value == 'object' && value !== null) {
            formData.append(key, JSON.stringify(value))
          } else {
            formData.append(key, value)
          }
        }
        formData.append('pdf', this.lab_result.pdf, this.lab_result.pdf.name)
        this.axios.post('/lab_results/upload', formData)
          .then((res) => {
            this.$set(this.lab_result, 'uuid', res.data.uuid)
            this.$emit('pdf-uploaded')
            this.pdf = res.data.media.filter(x => x.collection_name == 'pdf')[0].original_url
          })
          .catch(error => {
            if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
          })
          .finally(() => {
            this.loading.pdf = false
            this.lab_result.pdf = null
          })
      }
    },
    validateNpi (npi) {
      if (npi) {
        if (isNaN(npi)) return false

        var add = 24
        npi.toString().split('').reverse().forEach((val, index) => {
          val = parseInt(val)
          if (((index % 2) + 2) % 2) {
            val = val * 2
          }
          add = add + (val >= 10 ? val - 9 : val)
        });

        return add % 10 == 0 || 'Invalid NPI number'
      }
      return true
    },
    verifyZip (zip, field) {
      if (zip?.length == 5) {
       fetch(`https://ziptasticapi.com/${zip}`)
          .then(response => response.json())
          .then(data => {
            this.$set(this.lab_result[field], 'city', this.$options.filters.capitalize(data.city))
            this.$set(this.lab_result[field], 'state', data.state)
          })
      }
    },
    verifyNpi (npi) {
      fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(`https://npiregistry.cms.hhs.gov/api?version=2.1&number=${npi}`)}`)
        .then(response => response.json())
        .then(data => {
          let results = JSON.parse(data.contents)
          if (results.result_count == 1) {
            let provider = this.lab_result.provider
            this.$set(provider, 'first_name', this.$options.filters.capitalize(results.results[0].basic.first_name))
            this.$set(provider, 'last_name', this.$options.filters.capitalize(results.results[0].basic.last_name))
            this.$set(provider, 'middle_name', this.$options.filters.capitalize(results.results[0].basic.middle_name))
            if (results.results[0].addresses) {
              let location = results.results[0].addresses.filter(x => x.address_purpose == 'LOCATION')[0]
              this.$set(provider, 'phone', location.telephone_number)
              this.$set(provider, 'fax', location.fax_number)
              this.$set(provider, 'address', location.address_1)
              this.$set(provider, 'city', location.city)
              this.$set(provider, 'state', location.state)
              this.$set(provider, 'zip', location.postal_code)
            }
          }
        })
    },
    updateResistance () {
      switch (this.resistance_tested) {
        case false:
          this.lab_result.resistance = null
          break
        case true:
          this.lab_result.resistance = []
          break
      }
    },
  },
}
</script>
<style>
  .required .v-label::after {
    content: " *";
    color: red;
  }
  .theme--light.v-text-field.no-border > .v-input__control > .v-input__slot::before{
    border-width: 0;
  }
</style>
